import React from 'react';
 
class Intro extends React.Component {
    
    render() {
        return (
           
                <section id="intro" >
                    <div className="intro-content">
                        <h2>Making <span>your ideas</span><br />happen!</h2>
                        <div>
                            {/* <a href="#about" class="btn-get-started scrollto">Get Started</a>
                        <a href="#portfolio" class="btn-projects scrollto">Our Projects</a> */}
                        </div>
                    </div>
                </section>
           

        )

    }
}
export default Intro