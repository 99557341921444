import React, { Component } from 'react';
import './Footer.scss'
class Footer extends Component {

    render() {
        const year = new Date().getFullYear()
        return (
            <footer id="footer"  >

                <div className="copyright">
                    &copy; Copyright -  {year} <strong>CodersCrew info Tech Private Limited</strong>. All Rights Reserved
                </div>


            </footer>
        );
    }

}

export default Footer;
