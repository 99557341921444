import React from 'react';
import { updateObject, validate, axiosInstance } from 'Shared/utility';

import ReCAPTCHA from "react-google-recaptcha";
class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            controls: {
                "name": {
                    value: "", validation: {
                        isEmail: false,
                        minLength: 4
                    },
                    errors: [],
                    messages: {
                        minLength: "Please enter at least 4 chars"

                    },
                    valid: false,
                    touched: false
                },
                "email": {
                    value: "", validation: {
                        isEmail: true,

                    },
                    errors: [],
                    messages: {
                        isEmail: "Please enter a valid email"

                    },
                    valid: false,
                    touched: false
                },
                "subject": {
                    value: "", validation: {
                        minLength: 8,

                    },
                    errors: [],
                    messages: {
                        minLength: "Please enter at least 8 chars of subject"

                    },
                    valid: false,
                    touched: false
                },
                "message": {
                    value: "", validation: {
                        required: true,

                    },
                    errors: [],
                    messages: {
                        required: "Please write something for us  "

                    },
                    valid: false,
                    touched: false
                }
            },
            data: {},
            formIsValid: false,
            successmessage:false,
            errormessage:undefined
        };
    }




    onFormSubmit = async () => {
        if (this.state.formIsValid) {
            this.setState({successmessage:false,errormessage:undefined });
            const axios = axiosInstance();
            let url = "https://www.cc-chainops.com/copsapi/contactus";
            let that=this;
            await axios.post(url, this.state.data)
                                        .then(function (response) {
                                            that.setState({ successmessage:true ,data :{}});
                                        })
                                        .catch(function (error) {
                                            that.setState({ errormessage:error });
                                        });
          
        }
    }

    inputChangedHandler = (event) => {

        let controlName = event.target.name

        let elementvalue = event.target ? event.target.value : event.value ? event.value : event
        let validationresult = validate(elementvalue, this.state.controls[controlName].validation)

        const updatedControls = updateObject(this.state.controls, {
            [controlName]: updateObject(this.state.controls[controlName], {
                valid: validationresult.isValid,
                errors: validationresult.errors,
                touched: true
            })
        });
        let formIsValid = true;
        const data = updateObject(this.state.data, { [controlName]: elementvalue })
        for (let inputIdentifier in this.state.controls) {
            formIsValid = this.state.controls[inputIdentifier].valid && formIsValid;
        } 
        this.setState({ controls: updatedControls, formIsValid: formIsValid, data: data,successmessage:false,errormessage:undefined  });
    }


    render() {
        const recaptchaRef = React.createRef();
 
            
        return (

            <section id="contact" className="wow fadeInUp">
                <div className="container">
                    <div className="section-header">
                        <h2>Contact Us</h2>
                        <p>To receive our newsletter please complete the form above. We take your privacy seriously and we will not share your information with others. You can unsubscribe at any time</p>
                    </div>

                    <div className="row contact-info">

                        <div className="col-md-4">
                            <div className="contact-address">
                                <i className="ion-ios-location-outline"></i>
                                <h3>Company</h3>
                                <address>CodersCrew Info Tech Private Limited</address>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="contact-phone">
                                <i className="ion-ios-telephone-outline"></i>
                                <h3>Contact</h3>
                                <p>vasanth</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="contact-email">
                                <i className="ion-ios-email-outline"></i>
                                <h3>Email</h3>
                                <p><a href="mailto:info@example.com">mvasanth@coderscrew.tech</a></p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container mb-4">
                </div>

                <div className="container">
                    <div className="form">
                    <div id="sendmessage"  className={[this.state.successmessage ? 'show' : ' '].join(' ')} >Your message has been sent. Thank you!</div>
                        
                    <div id="errormessage" className={[this.state.errormessage ? 'show' : ' '].join(' ')}>Some unexpected  happen.Try after Some Time</div>
                        <form  className="contactForm" >

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <input onChange={this.inputChangedHandler} type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-msg="" />
                                    <div className={["validation", this.state.controls["name"].errors.indexOf("minLength") > -1 ? 'showerror' : ' '].join(' ')}>{this.state.controls["name"].messages["minLength"]}</div>
                                </div>
                                <div className="form-group col-md-6">
                                    <input onChange={this.inputChangedHandler} type="email" className="form-control" name="email" id="email" placeholder="Your Email" />
                                    <div className={["validation", this.state.controls["email"].errors.indexOf("isEmail") > -1 ? 'showerror' : ' '].join(' ')}>{this.state.controls["email"].messages["isEmail"]}</div>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" onChange={this.inputChangedHandler} name="subject" id="subject" placeholder="Subject" />
                                <div className={["validation", this.state.controls["subject"].errors.indexOf("minLength") > -1 ? 'showerror' : ' '].join(' ')}>{this.state.controls["subject"].messages["minLength"]}</div>
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" onChange={this.inputChangedHandler} name="message" rows="5" placeholder="Message"></textarea>
                                <div className={["validation", this.state.controls["subject"].errors.indexOf("minLength") > -1 ? 'showerror' : ' '].join(' ')}>{this.state.controls["message"].messages["required"]}</div>
                            </div>

                            <div className="form-group">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="normal"
                                    sitekey="6Lc6BpkUAAAAAMCR_pvNoUHeBelxhXLLmgvs30ms"
                                />
                            </div>
                            <div className="text-center"><button type="button" onClick={this.onFormSubmit} disabled={!this.state.formIsValid}>Send Message</button></div>

                        </form>
                    </div>

                </div>
            </section>

        );
    }

}

export default Contact