import React, { Component, Fragment } from 'react';


import Header from 'Layout/Header/Header'
import Footer from 'Layout/Footer/Footer'
import BasePage from './BasePage'

class Base extends Component {

    componentWillMount() {

    }

    componentWillUnmount() {

    }

    render() {

        return (
            <Fragment>
                <Header  />
                <BasePage>{this.props.children}</BasePage>
                <Footer />
            </Fragment>
        );
    }

}

export default Base;
