import axios from 'axios';

const servicePath ='';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const merge = (target, source) => {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (let key of Object.keys(source)) {
        if(target[key]){
            if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
        }
       }
  
    // Join `target` and modified `source`
    Object.assign(target || {}, source)
    return target
  }

 
export const  axiosInstance = ()  => {
    let instance = axios.create({
        baseURL:  servicePath,
        headers: { 'Authorization': 'Bearer ' +localStorage.getItem('feathers-jwt') }//here we add header to each request
        })

    return instance
}
 
 
export const getobjectfromcontrols =() =>{

}

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}

export const validate = ( value, rules ) => {
    let isValid = true;
    let result = {errors : []}
    if ( !rules ) {
        return true;
    }

    value = value?value.toString():'';

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
        pushErrors(isValid,result.errors,'required');
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
        pushErrors(isValid,result.errors,'minLength');
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
        pushErrors(isValid,result.errors,'maxLength');
    }
     

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
        pushErrors(isValid,result.errors,'isEmail');
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
        pushErrors(isValid,result.errors,'isNumeric');
    }


    result.isValid = isValid;

    return result;
}

const pushErrors = (isValid,errors,message) =>{

    if(!isValid){
        errors.push(message);
    }

}
