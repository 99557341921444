import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';


import Intro from './Pages/Intro';
import Aboutus from './Pages/Aboutus';
import Service from './Pages/Service';
import Contact from './Pages/Contact';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import './Home.scss'

class Home extends React.Component {

    componentWillMount() {
        configureAnchors({ offset: -120,scrollDuration: 1000 })
    }

    scrollNavigation = () => {
        let doc = document.documentElement;
        let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        console.log(top)
        let ele= document.getElementById('navBarcnr')
        if(top > 124 && ele)
        {
            ele.classList.add('nav-sticky');
        }
        else if(ele)
        {
            ele.classList.remove('nav-sticky');
        }
    }

    componentDidMount() {
        
        window.addEventListener("scroll", this.scrollNavigation, true);
      }

      componentWillUnmount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
      }

    state = {
        dropdownOpen: false
    }



    changeLanguage = lng => {
        this.props.i18n.changeLanguage(lng);
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }



    render() {
        return (
            <Fragment>
                <ScrollableAnchor id={"Intro"}  >
                    <Intro />
                </ScrollableAnchor>
                <ScrollableAnchor id={"About"}  >
                    <Aboutus />
                </ScrollableAnchor>
                <ScrollableAnchor id={"Service"}  >
                    <Service />
                </ScrollableAnchor>
                <ScrollableAnchor id={"Contact"}  >
                    <Contact />
                </ScrollableAnchor>



            </Fragment>
        );
    }
}

export default withTranslation()(Home);