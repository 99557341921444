import React,{ Component,Suspense } from 'react';

import './Custom.scss';

// Font Awesome
import '@fortawesome/fontawesome-free/css/brands.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';

// App Routes
import Base from 'Layout/Base';
import Home from 'Page/Home/Home';
import PageLoader from 'Helper/PageLoader';

class App extends Component {


  onMenuClick = (i) => {
    this.home.scrollToContent(i);
  };

  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
  

    return (
         <Suspense  fallback={<PageLoader/>}  >
            <Base onMenuClick= {this.onMenuClick} >
              <Home childRef={ ref => (this.home = ref) } />
           </Base>
         </Suspense>
       
    );

  }
}

export default App;
