import React from 'react';
import aboutimg from 'Root/assets/img/intro.png'

class Aboutus extends React.Component {
   
    render() {
        return (
            
                <section id="about" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 about-img">
                                <img src={aboutimg} alt="" />
                            </div>
                            <div className="col-lg-6 content">
                                <h2>About CodersCrew</h2>
                                <h3>CodersCrew creates software that powers transformation, enables enterprises to seize the opportunities of the application economy.CC Info Tech Will Work bridge the gap between IT and Business.</h3>
                                 <p>CoderCrew's first Blockchain Platform Beta Version Chain-Ops</p> 
                                 <p>CoderCrew's  Blockchain based LandRegistry  </p>
                                 <p>Whether you're a startup, small business, or established brand, get in touch and let CodesCrew unmask your  app's potential, help build MVP's for seed funding or simply build a companion app.</p>
                                
                            </div>
                        </div>
                    </div>
                </section>
           

        );
    }
};

export default Aboutus