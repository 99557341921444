import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Nav,
    Collapse,
    NavItem,
    NavbarToggler,  Row, Col,  Navbar
} from 'reactstrap';
 

import { connect } from 'react-redux';
 

import './Header.scss';

import logo from 'Root/assets/img/CC1.png'



class Header extends Component {

    state = {
        isOpen: false
    }

    UNSAFE_componentDidMount() {

    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleOffsidebar = e => {
        e.preventDefault()

    }

    // ["d-none", "d-lg-block", styles.topbar].join(' ')

    render() {
        return (
            <Fragment>

                <header className="header">
                    <section className="d-none d-lg-block topbar" >
                        <div className="container clearfix">
                            <div className="contact-info float-left">
                                <i className="fa fa-envelope"></i> <a href="mailto:mvasanth@coderscrew.tech">mvasanth@coderscrew.tech</a>
                                <i className="fab fa-connectdevelop"></i> CodersCrew Info Tech Private Limited
                             </div>
                            <div className="social-links float-right">
                                <a href="https://twitter.com/ccinfotechltd" target="_blank" rel="noopener noreferrer"  className="twitter"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.facebook.com/CCinfotectltd" target="_blank" rel="noopener noreferrer"  className="facebook"><i className="fab fa-facebook"></i></a>
                                {/* <a href="#" className="instagram"><i className="fab fa-instagram"></i></a>
                                <a href="#" className="google-plus"><i className="fab fa-google-plus"></i></a>*/}
                                <a href="https://www.linkedin.com/company/ccinfotechltd" target="_blank" rel="noopener noreferrer" className="linkedin"><i className="fab fa-linkedin"></i></a> 
                            </div>
                        </div>
                    </section>

                    <Row  id="navBarcnr">
                        <Col sm="12" md="12" lg="12">

                            <Navbar  className="navbar navbar-expand-lg container r"   >
                                <div className="logo float-left">
                                   <img src={logo} alt="" title="logo"/>
                                    {/* <h1 className=" "><a href="#" className="scrollto">Coders<span>Crew</span></a></h1> */}
                                </div>

                                <NavbarToggler className="navbar-toggler-right" onClick={this.toggle}  >
                                    <i className=" fa fa-bars"></i>
                                </NavbarToggler>


                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <Nav navbar className=" nav-menu ml-auto" >
                                        <NavItem>
                                            <a href="#Intro" >Home</a>
                                        </NavItem>
                                        <NavItem>
                                            <a href="#About"   >About Us</a>
                                        </NavItem>
                                        <NavItem>
                                            <a href="#Service"   >Services</a>
                                        </NavItem>
                                        <NavItem>
                                            <a href="#Contact"  >Hire Us</a>
                                        </NavItem>
                                        <NavItem>
                                            <a href="https://apps.coderscrew.tech" >Apps</a>
                                        </NavItem>
                                    </Nav>
                               </Collapse>
                            </Navbar>
                        </Col>
                    </Row>
                </header>
            </Fragment>
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);