import React from 'react';
class Service extends React.Component {
  
    render() {
        return (
            
                <section id="services">
                    <div className="container">
                        <div className="section-header">
                            <h2>Services</h2>
                            <p>A Great Idea Turned Into an App.We Turn Your Ideas Into Reality with CodersCrew.Just What Your Business Needs.We have provided following services </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="box wow fadeInLeft">
                                    <div className="icon"><i className="fa fa-laptop-code"></i></div>
                                    <h4 className="title">Web Development</h4>
                                    <p className="description">Build enterprise grade, web Responsive Applications with modern UI Frameworks.Offline loading of static assets (PWA-Progressive Web Applications) </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box wow fadeInRight">
                                    <div className="icon"><i className="fa fa-mobile-alt"></i></div>
                                    <h4 className="title">Mobile Development</h4>
                                    <p className="description">Hybrid applications for iOS & Android with single code base.Native App look and feel using modern mobile Framework React Native.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box wow fadeInLeft" data-wow-delay="0.2s">
                                    <div className="icon"><i className="fab fa-connectdevelop"></i></div>
                                    <h4 className="title">Block Chain</h4>
                                    <p className="description">Block chain POCS , Enterprise Applications using Hyper Ledger Fabric distributed ledger technology, with multiple organizations and kafKa orderer</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box wow fadeInRight" data-wow-delay="0.2s">
                                    <div className="icon"><i className="fab fa-mixcloud"></i></div>
                                    <h4 className="title">Cloud Application Development</h4>
                                    <p className="description">Instead Of developing Applications for Cloud,we develop applications in cloud in Azure,AWS using cloud services(Serverless,Lamda)</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box wow fadeInRight" data-wow-delay="0.2s">
                                    <div className="icon"><i className="far fa-paper-plane"></i></div>
                                    <h4 className="title">CI CD</h4>
                                    <p className="description"> Kubernetes based docker container deployment using azure devops</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box wow fadeInRight" data-wow-delay="0.2s">
                                    <div className="icon"><i className="fas fa-stream"></i></div>
                                    <h4 className="title">Business Process Automation</h4>
                                    <p className="description"> Business process Automation using opensource technologies</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box wow fadeInRight" data-wow-delay="0.2s">
                                    <div className="icon"><i className="fas fa-user-lock"></i></div>
                                    <h4 className="title">Identity Management</h4>
                                    <p className="description"> Scalable Identity Management for on Promise APIs and Apps</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box wow fadeInRight" data-wow-delay="0.2s">
                                    <div className="icon"><i className="fas fa-code"></i> </div>
                                    <h4 className="title">Micro Services</h4>
                                    <p className="description"> Scalable  Business Logics as microservices  using Nodejs/Dotnet</p>
                                </div>
                            </div>
                       </div>
                    </div>
                </section>
            

        );
    }
}

export default Service